<template>
  <div class='container'>
    <kcHeader></kcHeader>
    <div class="content">
        <div class="ks-aside">
          <div   :class="newIndex == index?'nav-item1':'nav-item'" v-for="(item,index) in newsLis" :key="item.id" @click="getNewsInfoData(item.id,index)">
              {{index + 1}}.{{item.title}}
          </div>
        </div>
        <div class="ks-contain">
            <h5>{{newInfo.title}}</h5>
            <div class="ks_body-contain">
                <div v-html="newInfo.content"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import kcHeader from '@components/kcHeader'
import {getNewsInfo, getNewsList} from "@assets/api/advice";
export default {
  name:'',
  components: {
    kcHeader
  },

  data () {
    return {
      newsLis:[],
      newIndex:0,
      newInfo:{},
    };
  },

  computed: {},

  created(){
    this.getNewsListData()
  },
  mounted() {
  },

  methods: {
    //列表
    getNewsListData(){
      getNewsList().then(res=>{
        this.newsLis = res.data.list
        console.log('res',res)
        if(this.$route.params.id){
          let index = this.newsLis.findIndex(item=>item.id == this.$route.params.id)
          this.getNewsInfoData(this.$route.params.id,index)

        }else{
          this.getNewsInfoData(this.newsLis[0].id,0)
        }
      })
    },
    //详情
    getNewsInfoData(id,index){
      this.newIndex = index
      let params = {
        id
      }
      getNewsInfo(params).then(res=>{
        console.log('res',res)
        this.newInfo = res.data
      })
    },
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/advice.less';
</style>